.block{
  width: 100%;
    background: linear-gradient(51deg, rgb(244, 248, 255) 0%, rgb(253, 249, 248) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 80px);
}

.smallBlock{
    border: solid;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-color: #e1e1e1; 
    border-width: 1px;

}

.firstBlock{
  position: relative;
    height: 520px;
   background-color: white;
    width: 400px;
    margin-left: 50px;
    overflow-y:auto;
}

.secondBlock{
    position: relative;
    height: 520px;
    width:500px;
    background-color: white!important;
    margin-left: 50px;
}

.thirdBlock{
  position: relative;
    height: 520px;
    width:400px;
    margin-left: 50px;
    background-color: white!important;
    color: rgb(69 147 221);
}





.firstRow{
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.buttons{
    margin-left: auto;
}

.button{
    height: 45px;
    width: 200px;
    background-color:rgb(69 147 221);
    color:white;
    border: solid;
    border-width: 0px;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    margin-left: 20px;
    font-weight: bold;
    
}

.delete{
    color: rgb(198, 0, 0);
    background-color: white;
    border-width: 1px;
    border-color: #e1e1e1;
}

.arrow{
    font-size: 30px;
    margin-left: 20px;
    background-color: inherit;
}

.analName{
    margin-left: 20px;
    font-weight: bold;
    font-size: 30px;
    color: rgb(69 147 221);
}

.secondRow{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
}

.headline{
    color: rgb(69 147 221);
    font-weight: bold;
    margin-left: 20px;
    margin-top: 20px;
}

.smallHeadline{
    color: rgb(100, 100, 100);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    margin-top: 40px;
}

.pepSeqHead{
width: 280px;
}

.pepSeq{
width: 100px;

}

.thirdRow{
   margin-left:20px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.thirdRowLabel{
    margin-right: 5px;
}
.thirdRowSI{
    margin-left: 5px;
}

.emptyBut{
    border: none;
    background-color: inherit
}

.modal {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    
    background-color: transparent; 
  }


.modal_content {
    background-color: transparent;
    margin: 15% auto; 
    padding: 20px;
    border: 2px solid rgba(255,255,255,.5);
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    width: 500px;
    height:150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    display: flex;
    justify-content: center;
    align-items: center;
   
    backdrop-filter: blur(30px);
    box-shadow: 0 0 30px rgba(0,0,0,.5);
  }

  .modalFirstLine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: 20px;
    color: #202020;
  }

  .checkmark{
    font-size: 25px;
    margin-left: 5px;
    color: rgb(69 147 221);
  }

  .closeModal{
    margin-top: 25px;
    width:120px;
    height: 45px;
    background-color: rgb(69 147 221);
    color: white;
    border: none;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.12);
    font-size: 18px;
    margin-left: 25px;
    border-color: #e1e1e1; 
  }

  .delModal{
    background-color: rgb(250, 88, 88);
    margin-left:90px;
  }

  .modalButtons{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

@media (max-width:1500px){
    .firstBlock{
        width: 350px;
    }

    .thirdBlock{
        width: 300px;
    }
}

@media (max-width:1300px){
    .firstBlock{
        margin-left: 10px;
    }

    .secondBlock{
        margin-left: 10px;
    }

    .thirdBlock{
        width: 300px;
        margin-left: 10px;
    }
}


.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .switchHolder{
    position: absolute;
    top:480px;
    width: 110px;
    left: calc( 100% - 132px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pageButtons{
    width: 90%;
    margin-left: 5%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
  }


  .pageBut{
    height: 45px;
    background-color: rgb(69 147 221);
    width: 100px;
    border: solid;
    border-width: 0px;
   
    border-radius: .25rem;
    color: white;
  }

  .disabled{
    background-color: #e1e1e1;
  }


  .dropdown{
    width: 120px;
  
  }

  .downloadButtons{
    display: flex;
    flex-direction: row;
    width: 99%;
    margin-left:calc( 3% - 20px);
    position:absolute;
    bottom: 10px;

  }