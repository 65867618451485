.block{
    background: rgb(26, 26, 26);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box{
    width: 400px;
    height: 600px;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box2{
    width: 400px;
    height: 300px;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head{
    background: rgba(241,241,241,0.8);
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:200px
}

.headLogin{
    width: 100%;
}

.logo{
    width: 200px;
    margin-top: 20px;
}


.text{
    font-weight: bold;
    font-size: 20px;
    margin-top: 30px;
}



.form{
    margin-top: 20px;
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;

}


.emailRow{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 55px;
    justify-content: space-between;
    align-items: start;
    position: relative;

}

.email{
    height: 40px;
    width:265px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #b4b7ba;
    
    padding-left: 35px

}

.emailIcon{
    position: absolute;
    top: 7px;
    left: 3px;
    color:#959595;
    height: 30px;
    width: 30px;
    z-index: 1;

}

.passwordRow{
    position: relative;
}

.password{
    height: 40px;
    width:260px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #b4b7ba;
    position: relative;
    padding-left: 40px
}

.lock{
    position: absolute;
    top: 7px;
    left: 5px;
    
    color:#959595;
    height: 30px;
    width: 30px;
    z-index: 1;

}


.passwordReset{
    margin-top: 40px;
    font-size: 17px;
    background-color: inherit;
    border: none;
}

.logBut{
    height: 107px;
    margin-top: 48px;
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    border:none;
    background-color: rgb(69 147 221);
    color:white;
    font-weight: bold;
    font-size: 20px;
}

.passwordRow2{
    margin-top: 10px;
    position: relative;
}


.resetError{
    margin-top: 40px;
    text-align: center;
}

.logIn{
    text-decoration: underline;
    color: rgb(69 147 221);
}

.logIn:hover{
    text-decoration: underline;
    color: rgb(255 171 255);
}

