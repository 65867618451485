.block{
    display: flex;
    flex-direction: row;
    width: 1200px;
    justify-content: space-between;
    height: calc(100vh - 80px);
}

.proteinBox{
    position: relative;
    width: 600px;
    height: 500px;
    background-color: white;
    border:solid;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-width: 1px;
    border-color: #e1e1e1; 
}

.proteinImg{
    width: 600px;
    margin-top: 50px;
}

.calcBox{
    width: 400px;
    height: 500px;
    background-color: white;
    border:solid;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-width: 1px;
    border-color: #e1e1e1; 
    color:rgb(69 147 221);
}

.calcTag{
    
    font-weight: bold;
    margin-top: 20px;
    margin-left: 5%;
}

.imageDesc{
    color:rgb(69 147 221);
    font-weight: bold;
    margin-top: 10px;
    margin-left: 2%;
    display: flex;
    flex-direction: row;
}


.calcFormVar{
}
.varLabel{
    margin-left: 8%;
    margin-top: 25px;
    width: 85%;
}

.dropdown{
    margin-top: 10px;
    width: 86%;
    margin-left: 8%;
    height: 42px;
}


.submitBut{
    margin-top: 30px;
    width:180px;
    margin-left: 110px;
    height: 35px;
    background-color: rgb(69 147 221);
    color: white;
    border: none;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.12);
    border-color: #e1e1e1; 
}

.pepLen{
    padding-left: 10px;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border: solid;
    border-color: #e1e1e1; 
    border-width: 1px;
    font-size: 15px;
    color:rgb(78, 78, 78)
}

.modal {
    display: block; 
    position: fixed; 
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; 
    height: calc(100vh - 80px);
    overflow: auto; 
    
    background-color: transparent 
  }


.modal_content {
    background-color: transparent;
    margin: 15% auto; 
    padding: 20px;
    border: 2px solid rgba(255,255,255,.5);
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    width: 500px;
    height:150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    box-shadow: 0 0 30px rgba(0,0,0,.5);
  }

  .modalFirstLine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: 20px;
    color: #202020;
  }

  .checkmark{
    font-size: 25px;
    margin-left: 5px;
    color: rgb(69 147 221);
  }

  .closeModal{
    margin-top: 25px;
    width:120px;
    height: 35px;
    background-color: rgb(69 147 221);
    color: white;
    border: none;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.12);
    font-size: 18px;
    border-color: #e1e1e1; 
  }




.mypanel > [class~="p-checkbox-box"] {
  background-color: rgb(69 147 221)!important;
  border-color: #888!important;
  border-width: 1px!important;
  color: #ffffff;
  margin: 2px 0 0 2px;
}

.myPanelOff > [class~="p-checkbox-box"] {
  border-color: #888!important;
  border-width: 1px!important;
  color: #ffffff;
  margin: 2px 0 0 2px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switchHolder{
  position: absolute;
  top:460px;
  width: 110px;
  left: calc( 100% - 132px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}