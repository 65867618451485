.block{
    background: rgb(26, 26, 26);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box{
    width: 400px;
    height: 600px;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head{
    background: rgba(241,241,241,0.8);
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:200px
}

.logo{
    width: 200px;
    margin-top: 20px;
}

.choice{
  
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
   
}

.login{
   width:50%;
   text-align: center;
}

.registerLink{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#9f9f9f;
}

.registerLink:hover{
    color:rgb(255 171 255)
}

.registerLink:hover .horizontalDe{
    background-color: rgb(255 171 255);
}


.register{
    width: 100%;
    text-align: center;
}

.horizontal{
    width: 100%;
    height: 2px;
    background-color: #5c666f;
    
}

.horizontalDe{
    width: 100%;
    height: 2px;
    background-color: #dfe0e2;
}


.form{
    margin-top: 40px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}


.emailRow{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 55px;
    justify-content: space-between;
    align-items: start;
    position: relative;
    margin-left: 37px;

}

.email{
    height: 40px;
    width:260px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #b4b7ba;
    
    padding-left: 38px

}

.emailIcon{
    position: absolute;
    top: 7px;
    left: 3px;
    color:#959595;
    height: 30px;
    width: 30px;
    z-index: 1;

}

.passwordRow{
    position: relative;
}

.password{
    height: 40px;
    width:260px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #b4b7ba;
    position: relative;
    padding-left: 40px
}

.lock{
    position: absolute;
    top: 7px;
    left: 5px;
    
    color:#959595;
    height: 30px;
    width: 30px;
    z-index: 1;

}


.passwordReset{
    margin-top: 40px;
    font-size: 17px;
    background-color: inherit;
    border: none;
    color: black;
}

.logBut{
    height: 107px;
    margin-top: 46px;
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    border:none;
    background-color: rgb(69 147 221);
    color:white;
    font-weight: bold;
    font-size: 20px;
}


.error{
    text-align: center;
    position: absolute;
    top:100px;
    font-size: 12px;
}