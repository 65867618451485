.block{
    background: rgb(26, 26, 26);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box{
    width: 400px;
    height: 530px;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head{
    background: rgba(241,241,241,0.8);
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
   position: relative;
    height:200px
}

.headText{
    margin-top: 30px;
    font-size: 25px;
}

.logo{
    width: 200px;
    margin-top: 60px;
}

.backCycle{
    position: absolute;
    left: 20px;
    top:40px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(69 147 221);
}

.backArrow{
    color: white;
}

.text{
    color:rgba(0,0,0,0.54);
    text-align: center;
    width: 90%;
    margin-top: 40px;
}


.form{
    margin-top: 30px;
  
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.emailRow{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 55px;
    justify-content: space-between;
    align-items: start;
    position: relative;

}

.email{
    height: 40px;
    width:265px;
    border-radius: 5px;
    border: solid;
    border-width: 1px;
    border-color: #b4b7ba;
    margin-left: 20px;
    padding-left: 35px

}

.emailIcon{
    position: absolute;
    top: 4px;
    left: 23px;
    color:#959595;
    height: 30px;
    width: 30px;
    z-index: 1;

}
.logBut{
    height: 110px;
    margin-top: 43px;
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    border:none;
    background-color: rgb(69 147 221);
    color:white;
    font-weight: bold;
    font-size: 20px;
}

.forgotError{
    text-align: center;
    font-size: small;
    width: 90%;
    position: absolute;
    top:53px
}
