.block{
    height: 80px;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 5px 40px rgba(0,0,0,0.12);
    position: relative;
    z-index: 2;
    
  
}

.logo{
    width: 130px;
   
  
}


.navbarMenu{
 
    min-width: 30px;
    height:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 280px;
    margin-left: calc(50% - 280px);
}

.navbarUser{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    height: 100%;
    
    justify-content: space-between;
    color: rgb(69 147 221);
    margin-left: auto;
 
    max-width: 300px;
}

a{
    text-decoration: none;
}

.userIcon{
    height: 35px;
    width: 35px;
    margin-left: 10px;
   
    
}

.userMenuBox{
    position: absolute;
    top:65px;
    display: flex;
    flex-direction: column;
   background-color: white;
    border: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: #e8ecf2;
    right: 10px;
}

.logout{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: red;
    width: 200px;
    margin-left: 20px;
    background-color: white;
    border:none
}

.logoutP{
    margin-left: 20px;
}


.userButton{
    border:none;
    background-color: inherit;
    color: rgb(69 147 221);
    margin-right: 10px;

}

.logoutIcon{
    color:rgb(169, 0, 0);
    font-size: 20px;
    margin-left: 2px;
}

.logoutText{
    color:rgb(169, 0, 0);
    margin-left: 10px;
}

.Icon{
    font-size: 20px;
    color:rgb(72, 101, 166)
}


.Text{
    margin-left: 10px;
    color:rgb(72, 101, 166)
}

.mailLink{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}