.block{
    width: 100%;
    height: 82px;
    border: solid;
    border-width: 1px;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-color: #e1e1e1; 
    border-width: 1px;
    display: flex;
    flex-direction: column;
   
    color:rgb(50 52 53);
    margin-top: 10px;
   
}

.calculationBlock{
    display: flex;
    flex-direction: row;
   background-color: white;
    align-items: center;
    color:rgb(50 52 53);
   
    height: 80px;
}

.calculation{
    display: flex;
    flex-direction: row;
    align-items: center;
    color:rgb(50 52 53);
    height: 80px;
    width: calc(75% + 50px);
   
}

.name{
    margin-left: 50px;
    font-weight: bold;
    width: 33%;
}

.date{
    width:33%;
}

.status{
    width: 33%;
    
}

.false{
    color:#efc959;
    background-color: #fff27d94;
    width: 130px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-color: #e1e1e1; 
}
.true{
color:rgb(50, 169, 72);
background-color: #a7e5aac2;
width: 100px;
height: 35px;
display: flex;
justify-content: center;
align-items: center;
border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
border-color: #e1e1e1; 
}


.logBut{
    font-size: 60px;
    height: 55px;
    width: 60px;
    border:none;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(118, 118, 118);
    
}

.progressLogBut{
    font-size: 60px;
    height: 55px;
    width: 60px;
    border:none;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(118, 118, 118);
}


.arrow{
    margin-right: 12px;
}

.error{
    color:rgb(226, 59, 59);
    background-color: #e5a7a7c2;
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-color: #e1e1e1; 
    }

