.block{
    color:rgb(69 147 221);
    width:70%;
    margin-left: 15%;
    margin-top: 70px;
    height: 700px;
}

.label{
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
}

.header{
    font-size: 30px;
    font-weight: 700;
}

.text{
    color: #767676;
    margin-top: 20px;
    font-size: 17px;
}

.form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.firstBlock{
    width: 50%;
}

.secondBlock{
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 50%;
}

.inputy{
    margin-top: 10px;
    height: 40px;
    width: 90%;
    border-radius: 5px;
    border:solid;
    border-width: 1px;
    border-color: #e1e1e1;
    padding-left: 10px;
}

.textArea{
    margin-top: 10px;
    height: 230px;
    width: 100%;
    border-radius: 5px;
    border:solid;
    border-width: 1px;
    border-color: #e1e1e1;
    padding-left: 10px;
}

.sendButton{
    background-color: rgb(69 147 221);
    height: 50px;
    width:140px;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border-radius: 5px;
    border:solid;
    border-width: 1px;
    border-color: #e1e1e1;
    margin-top: 10px;
    margin-left: auto;

}