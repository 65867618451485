.block{
    width: 100%;
    background: linear-gradient(51deg, rgb(244, 248, 255) 0%, rgb(253, 249, 248) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 80px);
   
    
}

.firstRow{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}
.list{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y:scroll;
}

.tag{
    font-size: 2.25rem;
    color:  rgb(50 52 53);
    font-weight: bold;
}

.newPredBut{
    background-color: rgb(69 147 221);
    width: 120px;
    color: white;
    height: 40px;
    font-size: 14.5px;
    border-style: none;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-color: #e1e1e1; 
    font-weight: 600;
}

.labelsRow{
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    color: rgb(118, 118, 118);
    font-weight: 470;
    width: 90%;
   
}

.nameTag{
    margin-left: 50px;
    width:25%;
    
}

.dateTag{

    width:25%
    
}

.statusTag{
    width: 25%;
  
}

.logTag{
   
}



.spaceEnd{
    height: 100px;
}


.dopa{
    background-color: red!important;
    height: 300px!important;
    width: 300px!important;
}


@keyframes loading{
    0%{
        opacity: 0%;
    }
    50%{
        opacity: 1;
    }

    100%{
        opacity: 0%;
    }
}





  .ring
{
  
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:rgb(69 147 221);
  letter-spacing:4px;
  text-transform:uppercase;
  margin-top: 200px;
  margin-left: 180px;
  
 
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid rgb(69 147 221);
  border-right:3px solid rgb(69 147 221);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}




@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }

  
}

.singleCalc{
    width: 100%;
    
}

.logi{
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    border-bottom:1px solid  #e1e1e1; ;
    border-left:1px solid  #e1e1e1; ;
    border-right:1px solid  #e1e1e1; ;
    height: 200px;
    min-height: 0px;
    overflow-y: auto;
}

.logLine{
    margin-left: 10px;
}