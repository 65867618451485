.block{
    height: 440px;
    
    width: 380px;
    display: flex;
    flex-direction: column;
    color: rgb(69 147 221);
    background-color: white;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border: solid;
    border-width: 1px;
    border-color: #e1e1e1; 
}

.bolder{
    font-weight: bold;
    margin-left: 10px;
    margin-top: 20px;
}

.text{
    margin-left: 35px;
    margin-top: 0px;
}

.input{
    margin-left: 35px;
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border-width: 1px;
    padding-left: 10px;
    border:solid;
    border-color: #e1e1e1; 
    border-width: 1px;
   
}




.submitBut{
    margin-top: 20px;
    width:180px;
    margin-left: 100px;
    height: 35px;
    background-color: rgb(69 147 221);
    color: white;
    border: none;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    box-shadow: 0px 5px 5px rgba(0,0,0,0.12);
    border-color: #e1e1e1; 
}



@keyframes loading{
    0%{
        opacity: 0%;
    }
    50%{
        opacity: 1;
    }

    100%{
        opacity: 0%;
    }
}





  .ring
{
  
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:rgb(69 147 221);
  letter-spacing:4px;
  text-transform:uppercase;
  margin-top: 200px;
  margin-left: 180px;
  
 
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid rgb(69 147 221);
  border-right:3px solid rgb(69 147 221);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}




@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }

  
}


.fileName{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8%;
  margin-top: 5px;
  color: black;
  font-size: 12px;
}

.validatorBox{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(242, 211, 211);
  margin-top: 10px;
  width:90%;
  margin-left: 5%;
  height: 40px;
  border-radius:0.25rem;
  animation:valid 0.2s linear ;
}

.validator{
  color:rgb(118, 16, 16);
}

@keyframes valid
{
  0%
  {
    height: 0px;
  }
  100%
  {
    height: 50px;
  }

  
}