.block{
    margin: 5px 0 0 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 90%;
    border: solid;
    border-width: 1px;
    border-color: grey;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    background-color: white;
    color: rgb(31, 94, 162);
    border-color: #e1e1e1; 

font-weight: bold;
}

.name{
width: 250px;
overflow: auto;
text-align: start;
margin-left: 25px;

}

.score{
    width: 100px;
    margin-left: 10px;
}


.active{
    border-color: rgb(255 171 255);
    border-width: 5px;
}
