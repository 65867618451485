.block{
    width: 100%;
    /* background: linear-gradient(51deg, rgb(244, 248, 255) 0%, rgb(253, 249, 248) 100%); */
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
}

.titleRow{
    margin-left: 60px;
    margin-top: 70px;
}

.headTitle{
    color: rgb(69 147 221);
    font-weight: bold;
    font-size: 40px;
}

.headSubTitle{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
    margin-left: 5px;
}

.greenDot{
    background-color: rgb(255 171 255);
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.headSubTitleText{
    margin-left: 5px;
    color:rgb(87, 126, 162)
}

.noLicenses{
    width: 100%;
    text-align: center;
    margin-top: 120px;
    color: rgb(69 147 221);
    font-weight: bold;
    font-size: 20px;

}