.block{
    width: 100%;
    height: calc(100vh + 280px);
    z-index: 30;
    background: linear-gradient(51deg, rgb(244, 248, 255) 0%, rgb(253, 249, 248) 100%);
    position: absolute;
    right: 0;
    
}

.block.close{
    animation-name: close;
    animation-duration: 1s;
 width: 0%;
}

.block.open{
    animation-name: openFull;
    animation-duration: 1s;
  
}

.navbar{
    height: 70px;
    box-shadow: 0px 5px 40px rgba(0,0,0,0.12);
    display: flex;
    flex-direction: row;
    align-items: center;

}
.closeBut{
    border: none;
    background-color: inherit;
    font-size: 30px;
    width: 60px;
    color:#1b4480
}

.exIcon{
    color:#1b4480;
    
}

.steps{
    display: flex;
    flex-direction: row;
    align-items: start;
    width: calc(100% - 60px);
    margin-left: 10px;
    height: 60px;
}

.step{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.stepNumber{
    color:#adbad1;
}

.stepLabel{
    color:#adbad1;
    margin-top: 5px;

}

.prevStepLabel{
color:rgb(69 147 221);
margin-top: 5px;
}

.currentStepLabel{
color:rgb(255 171 255);
margin-top: 5px;

}

.horizontal{
    height: 10px;
    background: linear-gradient(rgb(255 171 255) 0%, rgb(247 247 247) 100%);
    width: 100%;
    margin-top: 6px;
}

.second{
    animation-name: open;
    animation-duration: 1s;
}

@keyframes open{
    0% {width: 0%;}
    100% {width: 90%;}
}

@keyframes openFull{
    0% {width: 0%;}
    100% {width: 100%;}
}

@keyframes close{
    0% {width: 100%;}
    100% {width: 0%;
            display: none;}

}

.meat{
    display: flex;
    justify-content: center;
    height: calc(100vh - 80px);
    margin-top: 50px;
}

