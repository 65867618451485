.block{

    height: 100%;
    font-weight: bold;
    color: rgb(69 147 221);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    
}

.label{
    width: 90%;
    margin-top: 30px;
    text-align: center;
}

.underline{
    height: 10px;
    background: linear-gradient(rgb(255 171 255) 0%, rgb(247 247 247) 100%);
    width: 120px;
    margin-top: auto;
}