.block{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
    border-color: #e1e1e1; 
    border: solid;
    margin-left: 50px;
    border-color: #e1e1e1; 
}


.input{
    color:black
}
.input::file-selector-button {
    width: 120px;
    height: 30px;
    border-radius : 0.25rem 0.25rem 0.25rem 0.25rem;
}

.dragDrop{
    width: 80%;
    margin-left: 8%;
}

.dropper{
    
    height: 50px;
    width: 320px;
    border-style: dashed;
    border-radius: 20px;
    border-color: #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    color:  #707070;
    font-size: 12px;
}


.label{
    width: 100%;
    height: 100%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    
}
input[type="file"] {
    display: none;
}

.uploadIcon{
    font-size: 30px;
    margin-left: 30px;
}

.fileName{
    font-size:10px;
    margin-left:10px;
    margin-top: 5px;
    color: black;
}